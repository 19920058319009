import { HTTP_METHODS } from "src/constants";
import { makeCall } from "src/helpers";
import { isRLTLang } from "src/i18n";
import { store } from "src/configureStore";
import { isEmpty } from "lodash";
const FETCH_ENDPOINT = "/api/country_list";
const formatResponse = (items, param) =>
  items.map((item) => ({
    label: isRLTLang() ? item.labelNationalityAr : item.labelNationalityFr,
    value: item.idCountry,
    ...item,
  })).filter(item => item.idCountry !== 'CE')

const formatResponseForeignNationality = (items, param) =>{
  items = items.filter(item => item.labelCountryFr.toLowerCase() !== "tunisie");  
  return items.map((item) => ({
    label: isRLTLang() ? item.labelNationalityAr : item.labelNationalityFr,
    value: item.idCountry,
    ...item,
  })).filter(item => item.idCountry !== 'CE')}

export const NationalitiesUIHelper = (callback) => {
  const { token } = store.getState().common.auth || {}
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `Bearer ${token}` })
      .then((resp) => resolve(callback(formatResponse(resp.data))))
      .catch(() => reject(callback([])))
  );

};

export const ForeignNationalitiesUIHelper = (callback,recaptchaToken) => {
  const { token } = store.getState().common.auth || null
  let headers = {};
  if(token){
    headers = { Authorization: `Bearer ${token}` } 
  }else{
    headers["Captcha-Token"] = recaptchaToken; // Add token to headers 
  }
  
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false },headers)
      .then((resp) => resolve(callback(formatResponseForeignNationality(resp.data))))
      .catch(() => reject(callback([])))
  );

};

const formatValueOptions = (options) => {
  let values = {}
  options.forEach((option) => {
    values[option.idCountry] = isRLTLang() ? option.labelNationalityAr : option.labelNationalityFr
  })
  return values;
}
export const NationalitiesOptionsUIHelper = (callback) => {
  const { token } = store.getState().common.auth || null

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `Bearer ${token}` })
      .then((resp) => resolve(callback(formatValueOptions(resp.data))))
      .catch(() => reject(callback([])))
  );

};
